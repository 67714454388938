.EndOfResults {
    padding: 5rem 0;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: var(--font-grey);
}

.message {
    margin-bottom: 1rem;
}

.image-container {
    margin: 0 auto;
    width: 400px;
    height: 300px;
    text-align: center;
}

.image-container img {
    width: 100%;
    height: 100%;
}