

.Modal {
    position: fixed;
    z-index: 1000;
    max-height: 100vh;
    overflow: auto;
    width: 80%;
    top: 0;
    left : calc(10%);
    background-color: #ecf0f1;
}