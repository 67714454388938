.Button {
    display: block;
    width: 100%;
    padding: 1rem 0;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
    border-radius: 8px;
}



.facebook-btn {
    background-color: #02cef1;
}


.facebook-btn:hover {
    background-color: rgb(59, 241, 59);
}


.imagex-default-btn {
    background-color: #222222;
    color: white;
    font-size: 1rem;
}


.imagex-default-btn:disabled {
    background-color: rgb(236, 236, 236);
    color: black;
    cursor: not-allowed;
}
