.Profile {

}


.profile-info {
    display: flex;
    width: 60%;
    margin: 0 auto;
    justify-content: center;
    padding: 6rem 0;
    flex-wrap: wrap;
}


.profile-info__profile-pic {
    height: 10rem;
    width: auto;
    max-width: 10rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: silver;
}


.profile-info__profile-pic img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.profile-info__name-bio {
    flex-basis: auto;
    margin-left: 1rem;
}
.profile-info__name {
    
}

.profile-info__username {
    margin-bottom: 1rem;
}

.profile-info__edit-profile {
    margin-top: 1rem;
    margin-left: 1rem;
    min-width: 100px;
}


.profile-info__edit-profile a:link, .profile-info__edit-profile a:visited {
    border: 1.5px solid var(--font-grey);
    box-shadow: var(--font-grey) 0.5px 0.5px 0.5px;
    border-radius: 5px;
    padding: 0.5rem;
    text-decoration: none;
    font-size: 0.8rem;
    color: var(--font-grey);
    font-weight: bold;
}



/***************MEDIA QUERIES****************/

@media (max-width: 1000px) {
    .profile-info__profile-pic {
        flex-basis: 100%;
    }

    .profile-info {
        padding: 2rem 0;
    }

    .profile-info__name-bio {
        flex-basis: 100%;
        text-align: center;
    }

    .profile-info__edit-profile {
        flex-basis: 100%;
        text-align: center;
    }
}

