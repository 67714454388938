.Backdrop {
    background-color: #000;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
}


.backdrop--translucent {
    opacity: 0.5
}


.backdrop--transparent {
    opacity: 0.1;
}