.Loader {
  height: 200px;
  background-color: rgb(198, 248, 198);
}

.spinner {
  margin: 0 auto;
  width: 100%;
  height: 360px;
}

.spinner--invisible {
  display: none;
}

/*****************MEDIA QUERIES*********************/

@media (max-width: 540px) {
  .spinner {
    height: 180px;
  }
}
