.hero-container {
    background: linear-gradient(
        rgba(0, 0, 0, 0.55), 
        rgba(0, 0, 0, 0.55)
      ), url('../../assets/images/homepage-hero.jpg');
    height: 85vh;
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: center;
    justify-content: center;
}


