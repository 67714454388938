.image-grid {
    max-width: 1320px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-auto-rows: 50px;
    grid-auto-flow: dense;
    grid-gap: 5px;
}


.square {
    grid-row: span 8;
}

.landscape-1 {
    grid-row: span 6; 
}

.landscape-2 {
    grid-row: span 4;
}

.landscape-3 {
    grid-row: span 3;
}

.portrait-1 {
    grid-row: span 10;
}

.portrait-2 {
    grid-row: span 12;
}

.portrait-3 {
    grid-row: span 14;
}

.image__box {
    position: relative;
}

.image__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: zoom-in;
    padding-top: 1rem;
    opacity: 0;
}


.image__overlay:hover {
    background-color: rgba(0,0,0,.5);
    opacity: 1;
}


.image__box img {
    width: 100%;
    overflow: hidden;
    height: 100%;
}


.image__options {
    position: absolute;
    top: 1rem;
    display: flex;
    width: 100%;
    opacity: 0;
}


.image__options--visible {
    opacity: 1;
}

.download-button  a:link , .download-button  a:visited {
    text-decoration: none;
    color: black;
}


.download-button  a:hover , .download-button  a:active {
    text-decoration: none;
    color: black;
}

.download-button {
    border: 1px solid #adacac;
    height: 30px;
    width: 45px;
    padding-top: 5px;
    border-radius: 5px;
    text-align: center;
    margin-left: auto;
    margin-right: 1rem;
    background-color: #d6d6d6;
    cursor: pointer;
    margin-top: 5px;
}


.download-button a {
    height: 100%;
    width: 100%;
    display: block;
}


.download-button:hover {
    background-color: #f3f3f3;
}


.like-btn {
    margin-left: 1rem;
    cursor: pointer;
    font-size: 1rem;
    color: rgb(118,118,118);
    padding: 5px 10px;
    text-align: center;
    background-color: hsla(0,0%,100%,.9);
    border-radius: 5px;
}

.like-btn--liked {
    background-color: #f15151;
    color: white;
}


.like-btn span {
    position: relative;
    top: 5px;
}


.like-btn:hover span {
    color: #111111;
}






/********************MEDIA QUEIRES ************************/


@media (max-width: 538px) {
    .image-grid {
        width: 350px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        justify-content: center;
        grid-auto-rows: 35px;
    }

    .image__overlay {
        opacity: .1;
    }

    .image__options {
        opacity: 1;
    }
}




@media (min-width: 539px) and (max-width: 1074px) {
    .image-grid {
        width: 500px;
        grid-gap: 8px;
    }
}


@media (min-width: 1075px) and (max-width: 1614px) {
    .image-grid {
        width: 875px;
    }
}

