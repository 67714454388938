.text-input {
    width: 100%;
}

.text-input {
    width: 100%;
    height: 40px;
    font-size: 20px;
    padding: 0px 10px;
    margin-top: 10px;
    outline: none;
    border: 1px solid black;
    border-radius: 4px;
}


.text-input--invalid {
    border-color: red;
}



.text-input__label {
    color: inherit;
}

.text-input__label--invalid {
    color: red;
}


.text-input__error-msg {
    color: red;
    font-size: 0.8rem;
}

.text-area {
    height: 100%;
    width: 100%;
    padding: 10px;
}