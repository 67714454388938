* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
  

:root {
  --font-grey: #767676;
  --heart-red: #860606;
  --screen-large: 1000px;
  --unsplash-black: #111111;
  --screen-min: 538px;
}

h1 {
  font-size: 3rem;
}

h2 {

}

h3 {

}

h4 {
  font-size: 1rem;
}

h5 {

}

h6 {
  
}




/********************MEDIA QUEIRES ************************/

@media (max-width: 538px){
  html {
    font-size: 12px;
  }
}