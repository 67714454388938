.Signup {
    display: flex;   
    height: 100vh;
    align-items: stretch;
    flex-wrap: wrap;
}

.image-container {
    flex-basis: 40%;
    background-color: slateblue;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-container {
    margin-left: auto;
    flex-basis: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.signup-info {
    width: 77.5%;
    padding: 20px;
}

.facebook-join {
    margin-top: 80px;
    margin-bottom: 25px;
}


.signup-info h1, .signup-info h4 {
    text-align: center;
}

.signup-info > h1 {
    margin-bottom: 20px;
}


.signup-info__login-option{
    margin-bottom: 2rem;
}


.signup-info span {
    font-size: 30px;
    color: white;
}


.signup-info p {
    text-align: center;
}

.signup-info p:last-child {
    margin-top: 10px;
}


.form-container form > div {
    padding: 15px 0;
}

.name-container {
    display: flex;
    flex-wrap: wrap;
}


.name-container__first-name {
    flex-basis: 48%;
}

.name-container__last-name {
    flex-basis: 48%;
}

.name-container > div:nth-child(2) {
    margin-left: auto;
}


/******************************MEDIA QUERIES***************************/

@media (max-width: 1000px) {
    .image-container {
        flex-basis: 100%;
        height: 400px;
    }

    .form-container {
        flex-basis: 100%;
    }
}


@media (max-width: 538px) {
    .image-container {
        height: 300px;
    }
    .name-container__first-name {
        flex-basis: 100%;
        margin-bottom: 2rem;
    }

    .name-container__last-name {
        flex-basis: 100%;
    }

    .signup-info {
        width: 90%;
    }
}