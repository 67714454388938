.container__file {
  color: pink;
}

.container {
  display: flex;
  flex-flow: column;
  height: 90vh;
  margin: 1rem 2rem 1rem 2rem;
  border: 4px solid black;
  align-items: center;
}

.spinner {
  width: 100%;
  height: 540px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner__text {
  text-align: center;
}

.form {
  margin-top: auto;
  text-align: center;
}

.form__image {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.form__image img {
  width: 50px;
  height: auto;
}

.form__title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.form__btn {
  height: 50px;
  padding: 0 20px;
  outline: none;
  border: 1px solid black;
  border-radius: 25px;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s ease;
  transition: background-color 0.2s ease;
}

.form__btn:hover {
  background-color: #000000;
  color: white;
}

.form__file {
  display: none;
}

.container__image-constraints {
  margin-top: auto;
  margin-bottom: 2rem;
  text-align: center;
}
